<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList"
			@clearSearch="clearSearch" @sortColumn="onSortColumn">
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.fans_id" size="small" clearable placeholder="按用户ID查询"></el-input>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="fans_id" title="用户信息" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="phone" title="手机号" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="city" title="来源" align="center" min-width="50px">
				<template v-slot="{row}">
					{{$userArea[row.city]}}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="is_vip" title="是否VIP" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="act_id" title="活动ID" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="val" title="电量/时长" sortable align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="ctime" title="时间" sortable align="center" min-width="80px" />
		</Tables>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-charge-users',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '购买记录',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {
					act_id: this.$route.query.id
				},
				// 用户搜索
				sLoading: false,
			}
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				const res = await this.$api.Market.ChargeCoupon.users(params, searchData)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {act_id: this.$route.query.id};
			},
			onSortColumn(e){ //
				if(this.searchFormData.sortCol == e.sortList[0].property && this.searchFormData.sortBy == e.sortList[0].order) return;
				this.searchFormData.sortCol = e.sortList[0].property;
				this.searchFormData.sortBy = e.sortList[0].order;
				this.getList();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
